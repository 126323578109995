<template>
  <div>
    <EtablissementScolaireFormComponent
      :is-edit-form="true" 
    />
  </div>
</template>

<script>
import EtablissementScolaireFormComponent from '../../components/etablissementScolaire/EtablissementScolaireFormComponent';

export default {
  name: 'EditEtablissementScolaireContainer',
  components: {
    EtablissementScolaireFormComponent,
  },
  data() {
    return {

    };
  },
};
</script>